import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FilePond } from "react-filepond"; //Filepond is the upload tool we're using
//import { css } from "@emotion/core";
import RingLoader from "react-spinners/ClipLoader";
import axios from "axios";
import "filepond/dist/filepond.min.css";
import "../../App.css";

class UploadPortal extends React.Component {
  constructor(props) {
    super(props);
    this.captchaState = false;
    this.state = {
      lastName: "",
      policyNumber: "",
      emailAddr: "",
      invalidLastName: false,
      invalidPolicyNumber: false,
      invalidDocumentType: false,
      invalidFiles: false,
      uploadSuccess: false,
      errorMsg: "",
      chosenFileType: "",
      loading: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onExpired = this.onExpired.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeLName = this.handleChangeLName.bind(this);
    this.handleChangePolicyNumber = this.handleChangePolicyNumber.bind(this);
    this.handleChangeFT = this.handleChangeFT.bind(this);
  }

  handleChangeLName(event) {
    this.setState({ lastName: event.target.value });
  }

  handleChangePolicyNumber(event) {
    this.setState({ policyNumber: event.target.value });
  }

  handleChangeFT(event) {
    this.setState({
      chosenFileType: event.target.value,
      invalidDocumentType: false,
    });
  }

  async uploadFilesProtocol(successStatus) {
    let errors = false;

    if (successStatus) {
      this.setState({ loading: true });

      for (let i = 0; i < this.pond.getFiles().length; i++) {
        try {
          const formData = new FormData();
          const { chosenFileType, policyNumber, lastName } = this.state;
          const { file, filenameWithoutExtension, fileExtension } =
            this.pond.getFile(i);
          if (file.size > 52428800) {
            this.setState({
              uploadSuccess: false,
              errorMsg:
                "The file size is bigger than 50MB, please choose a smaller file.",
            });

            this.captchaState = false;
            this.refs.captcha.reset();
            errors = true;
          } else {
            formData.append("fileType", chosenFileType);
            formData.append("policyNumber", policyNumber);
            formData.append("lastName", lastName);
            formData.append("doc", file);
            formData.append(
              "fileName",
              `${filenameWithoutExtension}_${Date.now()}`
            );
            formData.append("fileExtension", fileExtension);
            await axios.post("/api/upload", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          }
        } catch (e) {
          errors = true;
          console.log(e);
        }
      }

      this.setState({ loading: false });

      if (errors) {
        this.setState({
          uploadSuccess: false,
          errorMsg:
            "Something went wrong. Please try again later, or contact IT if the issue persists.",
        });

        this.captchaState = false;
        this.refs.captcha.reset();
        //Dont want to reset form elements because that would be annoying (other than captcha)
      } else {
        this.setState({
          uploadSuccess: true,
          errorMsg: "Files uploaded successfully.",
          lastName: "",
          policyNumber: "",
        });

        //Will reset all of the form elements
        this.captchaState = false;
        this.refs.lname.value = "";
        this.refs.policy_number.value = "";
        this.refs.captcha.reset();
        this.pond.removeFiles();
        this.refs.typeSelector.value = "";
        //console.log("Uploading because we got: "+successStatus);
      }
    } else {
      //console.log("Not uploading because we got: "+successStatus);
    }
  }

  validateForm(whitelist) {
    return new Promise((resolve, reject) => {
      let infractions = 0; //Our infractions counter (How many files had a bad extention)
      let valid = true;

      this.setState({ uploadSuccess: false });

      //Cleanse our filepond
      if (this.pond.getFiles().length === 0) {
        //Empty filepond
        this.setState({
          errorMsg: "Please input a file before attempting to submit.",
          invalidFiles: true,
        });
        valid = false;
      } else {
        for (let i = this.pond.getFiles().length - 1; i >= 0; i--) {
          var extension = this.pond.getFile(i).fileExtension;

          if (!whitelist.includes(extension.toLowerCase())) {
            this.pond.removeFile(i);
            infractions++;
          }
        }

        if (infractions > 0) {
          let genErrorMsg = "Allowed files are ";
          for (let i = 0; i < whitelist.length; i++) {
            if (i !== whitelist.length - 1) {
              genErrorMsg += " ." + whitelist[i] + ", ";
            } else {
              genErrorMsg += "or ." + whitelist[i] + ".";
            }
          }

          this.setState({
            invalidFiles: true,
            //TODO: Dynamically generate file types
            errorMsg: genErrorMsg,
          });
          valid = false;
        } else {
          this.setState({ invalidFiles: false });
        }
      }

      if (this.state.chosenFileType === "") {
        //Will trigger if the user hasn't chosen a file type
        this.setState({
          invalidDocumentType: true,
          errorMsg: "Please select a file type.",
        });
        valid = false;
      } else {
        this.setState({ invalidDocumentType: false });
      }

      if (!this.captchaState) {
        this.setState({
          errorMsg: "Please complete the captcha.",
        }); //Will trigger if captcha isn't valid
        valid = false;
      }

      if (this.state.policyNumber === "") {
        //Will trigger if the user hasn't provided a policy number
        this.setState({
          invalidPolicyNumber: true,
          errorMsg: "Please provide a policy number.",
        });
        valid = false;
      } else {
        // Validate policyNumber doesn't contain any invalid filename characters
        if (this.state.policyNumber.match(/[/\\?%*:|"<>]/)) {
          this.setState({
            invalidPolicyNumber: true,
            errorMsg:
              'Policy number cannot contain any of the following characters: / \\ ? % * : | " < >',
          });
          valid = false;
        } else {
          this.setState({ invalidPolicyNumber: false });
        }
      }

      if (this.state.lastName === "") {
        //Will trigger if the user hasn't provided a last name
        this.setState({
          invalidLastName: true,
          errorMsg: "Please provide a last name.",
        });
        valid = false;
      } else {
        // Validate lastName doesn't contain any invalid filename characters
        if (this.state.lastName.match(/[/\\?%*:|"<>]/)) {
          this.setState({
            invalidLastName: true,
            errorMsg:
              'Last name cannot contain any of the following characters: / \\ ? % * : | " < >',
          });
          valid = false;
        } else {
          this.setState({ invalidLastName: false });
        }
      }

      return resolve(valid);
    });
  }

  handleSubmit(event) {
    this.validateForm(["jpg", "png", "pdf", "doc", "docx"]).then(
      (successStatus) => this.uploadFilesProtocol(successStatus)
    );
  }

  //Handlers for the reCaptcha
  onChange() {
    this.captchaState = true; //Will verify the captcha is completed and set captchaState to true
  }

  onExpired() {
    this.captchaState = false; //If the captcha verification expires, it will be reset to false
  }

  render() {
    return (
      <div style={{ marginTop: "80px", marginBottom: "70px" }}>
        <form
          onSubmit={this.handleSubmit}
          className={"form-wrapper"}
          action="post"
          ref="form"
        >
          <div className="disclaimer">
            <span className="disclaimer-head">
              PLEASE DO NOT USE THIS FEATURE TO UPLOAD ANNUITY DOCUMENTS. THIS
              FEATURE SHOULD BE USED FOR LONG AND SHORT TERM POLICIES ONLY.
            </span>
            <br />
            <span>
              For faster handling of your request, please use a descriptive name
              for your document rather than a generic name (cancellation vs
              letter) and ensure you have included contact information in case
              we have follow up questions. Thank you!
            </span>
          </div>
          <div
            style={{
              float: "left",
              display: "flex",
              flexDirection: "column",
              width: "40%",
            }}
            id="infoWrapper"
          >
            <h1
              className={"error-message"}
              style={
                !this.state.uploadSuccess
                  ? { color: "#ED1111" }
                  : { color: "green" }
              }
            >
              {this.state.errorMsg}&nbsp;
            </h1>
            <div className="form-group">
              <label htmlFor="lname_1" className={"formLabel"}>
                Last Name <span style={{ color: "#DB1500" }}>*</span>
              </label>
              <input
                type="text"
                id="lname_1"
                name="lname"
                style={
                  this.state.invalidLastName
                    ? { backgroundColor: "#F99" }
                    : { backgroundColor: "white" }
                }
                value={this.state.value}
                onChange={this.handleChangeLName}
                className={"form-control"}
                ref="lname"
              />
            </div>
            <div className="form-group">
              <label htmlFor="policy_number_1" className={"formLabel"}>
                Policy Number <span style={{ color: "#DB1500" }}>*</span>
              </label>
              <input
                type="text"
                id="policy_number_1"
                name="policy_number"
                style={
                  this.state.invalidPolicyNumber
                    ? { backgroundColor: "#F99" }
                    : { backgroundColor: "white" }
                }
                value={this.state.value}
                onChange={this.handleChangePolicyNumber}
                className={"form-control"}
                ref="policy_number"
              />
            </div>
            <div className="form-group">
              <label htmlFor="document_type_1" className={"formLabel"}>
                Document Type <span style={{ color: "#DB1500" }}>*</span>
              </label>
              <select
                id="document_type_1"
                name="document_type"
                className={"form-control_Select"}
                onChange={this.handleChangeFT}
                style={
                  this.state.invalidDocumentType
                    ? { backgroundColor: "#F99" }
                    : { backgroundColor: "white" }
                }
                defaultValue=""
                ref="typeSelector"
              >
                <option value="" disabled hidden>
                  Select One
                </option>
                <option value="eligibility">Eligibility</option>
                <option value="claims">Claim</option>
                <option value="policy">Policy</option>
                <option value="bankdraft">Bank Draft</option>
                <option value="cancellations">Cancellation</option>
                <option value="appeals">Appeal</option>
                <option value="other">Other</option>
              </select>
            </div>
            <ReCAPTCHA
              sitekey={
                process.env.REACT_APP_RECAPTCHA_SITEKEY ||
                window.RECAPTCHA_SITEKEY
              }
              onChange={this.onChange}
              onExpired={this.onExpired}
              ref="captcha"
            />
            <div className="form-group">
              <input
                type="button"
                value="Submit"
                className={"submitButton"}
                onClick={!this.state.loading ? this.handleSubmit : () => {}} //Will just do nothing if loading
                style={
                  this.state.loading
                    ? {
                        backgroundColor: "#888",
                        color: "#888",
                        cursor: "default",
                      }
                    : {
                        backgroundColor: "#007bff",
                        //marginBottom: '35px'
                      }
                }
              />
              <RingLoader
                loading={this.state.loading}
                css={{
                  float: "left",
                  position: "absolute",
                  bottom: "33px",
                  alignSelf: "center",
                  zIndex: "1000",
                }}
                color="white"
              />
            </div>
          </div>
          <div style={{ float: "left", width: "60%", paddingTop: "55px" }}>
            <FilePond ref={(ref) => (this.pond = ref)} allowMultiple={true} />
          </div>
        </form>
      </div>
    );
  }
}

export default UploadPortal;
